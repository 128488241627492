import React from "react";
import styled from "styled-components";
import zendesk from "helpers/zendesk";
import SpanLink from "app/components/SpanLink";
import Flex from "app/components/Flex";
import { P1 } from "app/components/Typography";
import Div from "app/components/Div";
import Alert from "app/components/Alert";
import { PaymentMethodType, PaymentSource } from "services/graphql";
import { convertPaymentSourceToString } from "helpers/convertPaymentSourceToString";

const Label = styled(Div)`
  font-size: 18px;
  font-weight: bold;
`;

const SubInfoWrapper = styled(Flex)`
  width: 100%;
  color: ${({ theme }) => theme.colors.black};
  padding: 20px 0;
  cursor: auto;
  align-items: center;
  justify-content: space-between;
  border-bottom: 2px solid ${({ theme }) => theme.colors.lightGrey};
`;

interface Props {
  primaryPaymentSource: PaymentSource;
  handleTogglePaymentDialog: () => void;
  showUpdateCard: boolean;
}

export const PaymentMethod = ({
  primaryPaymentSource,
  handleTogglePaymentDialog,
  showUpdateCard,
}: Props) => {
  if (!primaryPaymentSource) {
    return (
      <SubInfoWrapper>
        <Label>Payment Method</Label>
        <P1 textTransform="none">
          <Div textAlign="right">
            <SpanLink color="blue" onClick={handleTogglePaymentDialog}>
              {showUpdateCard ? "Hide" : "Set"} Payment Method
            </SpanLink>
          </Div>
        </P1>
      </SubInfoWrapper>
    );
  }

  const { type } = primaryPaymentSource;
  const { paymentMethodCopy } = convertPaymentSourceToString(
    primaryPaymentSource
  );

  switch (type) {
    case PaymentMethodType.ApplePay:
    case PaymentMethodType.GooglePay:
    case PaymentMethodType.Card:
    case PaymentMethodType.PaypalExpressCheckout:
      return (
        <SubInfoWrapper>
          <Label>Payment Method</Label>
          <P1 textTransform="none" textAlign="right">
            {paymentMethodCopy}
            <Div>
              <SpanLink color="blue" onClick={handleTogglePaymentDialog}>
                {showUpdateCard ? "Hide" : "Update"} Payment Method
              </SpanLink>
            </Div>
          </P1>
        </SubInfoWrapper>
      );
    default:
      return (
        <Alert variant="danger">
          Unable to load payment method. Please
          <SpanLink onClick={() => zendesk("webWidget", "open")}>
            {" "}
            contact us for help!
          </SpanLink>
        </Alert>
      );
  }
};
