import { useMutation } from "@apollo/client";
import {
  SUBSCRIPTION_REACTIVATE,
  SUBSCRIPTION_RESUME,
  USER_UPDATE_PAYMENT_SOURCE,
} from "graphql/mutations";
import { usePlans } from "hooks/Checkout";
import { usePrimarySubscription } from "hooks/Subscriptions/usePrimarySubscription";
import { useSubscriptionSwitchPlanMutation } from "services/graphql";

export function useUserUpdatePaymentSource(options: any) {
  const [
    execUserUpdatePaymentSource,
    userUpdatePaymentSourceResults,
  ] = useMutation(USER_UPDATE_PAYMENT_SOURCE, options);

  const userUpdatePaymentSource = ({ variables }: any) => {
    const { gatewayProvider, type, token } = variables;

    return execUserUpdatePaymentSource({
      variables: {
        gateway: {
          provider: gatewayProvider,
          token,
        },
        type,
      },
    });
  };
  return {
    userUpdatePaymentSource,
    userUpdatePaymentSourceResults,
  };
}

export function useSubscriptionResume(options?: any) {
  const [subscriptionResumeMutation, subscriptionResumeResults] = useMutation(
    SUBSCRIPTION_RESUME,
    options
  );

  const subscriptionResume = ({ variables }: any) => {
    return subscriptionResumeMutation({
      variables,
    });
  };

  return {
    subscriptionResume,
    subscriptionResumeResults,
  };
}

export function useSubscriptionReactivate(options: any) {
  const [
    subscriptionReactivateMutation,
    subscriptionReactivateResults,
  ] = useMutation(SUBSCRIPTION_REACTIVATE, options);

  const subscriptionReactivate = ({ variables }: any) => {
    const { subscriptionId } = variables;
    return subscriptionReactivateMutation({
      variables: {
        subscriptionId,
      },
    });
  };

  return { subscriptionReactivate, subscriptionReactivateResults };
}

export function useSubscriptionSwitchPlan() {
  const { refetchPrimarySubscription } = usePrimarySubscription();
  const { refetch: refetchPlans } = usePlans();
  const [
    subscriptionSwitchPlanMutation,
    { loading: subscriptionSwitchPlanLoading },
  ] = useSubscriptionSwitchPlanMutation();

  const subscriptionSwitchPlan = async ({
    subscriptionId,
    planId,
  }: {
    subscriptionId: string;
    planId: string;
  }) => {
    return subscriptionSwitchPlanMutation({
      variables: {
        subscriptionId,
        planId,
      },
      onCompleted() {
        refetchPrimarySubscription();
        refetchPlans();
      },
    });
  };

  return { subscriptionSwitchPlan, subscriptionSwitchPlanLoading };
}
