import React, { useState } from "react";
import styled from "styled-components";
import Moment from "react-moment";
import removeHyphen from "helpers/removeHyphen";
import removeUnderscore from "helpers/removeUnderscore";
import zendesk from "helpers/zendesk";
import SpanLink from "app/components/SpanLink";
import Loader from "app/components/Loader";
import Alert from "app/components/Alert";
import Flex from "app/components/Flex";
import { P1, H3 } from "app/components/Typography";
import Div from "app/components/Div";
import PoweredByStripeIcon from "app/components/PoweredByStripeIcon";
import CardPaymentOptions from "app/components/CardPaymentOptions";
import StripeCard from "app/components/StripeCard";
import PaypalButton from "app/components/PaypalButton";
import { Elements } from "@stripe/react-stripe-js";
import { Token, loadStripe } from "@stripe/stripe-js";
import useUserSubscription from "hooks/Subscriptions/useUserSubscription";
import {
  SUBSCRIPTION_STATUSES,
  PAYMENT_GATEWAYS,
  PAYMENT_METHOD_TYPES,
} from "constants/index";
import env from "helpers/env";
import { useHistory } from "react-router";
import { Routes } from "constants/routes";
import { useUserUpdatePaymentSource } from "./hooks";
import { PaymentMethod } from "./PaymentMethod";

const stripePromise = loadStripe(env("PUBLIC_STRIPE_PUBLISHABLE_KEY"));

const CreditWrapper = styled(Div)`
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  padding: 15px 0 0 0;
  margin-top: 8px;
`;

const Label = styled(Div)`
  font-size: 18px;
  font-weight: bold;
`;

const SubInfoWrapper = styled(Flex)`
  width: 100%;
  color: ${({ theme }) => theme.colors.black};
  padding: 20px 0;
  cursor: auto;
  align-items: center;
  justify-content: space-between;
  border-bottom: 2px solid ${({ theme }) => theme.colors.lightGrey};
`;

const SubscriptionInfo = () => {
  const history = useHistory();
  const [updateCardError, setUpdateCardError] = useState(null);
  const [updateCardData, setUpdateCardData] = useState(null);
  const [showUpdateCard, setShowUpdateCard] = useState(false);

  const [data = {}, userLoading] = useUserSubscription();
  const {
    userUpdatePaymentSource,
    userUpdatePaymentSourceResults,
  } = useUserUpdatePaymentSource({
    onError: () => {
      setUpdateCardError(true);
      setShowUpdateCard(false);
    },
    onCompleted: () => {
      setUpdateCardData(true);
      setShowUpdateCard(false);
    },
  });

  if (userLoading) {
    return <Loader />;
  }

  const {
    me: {
      primaryPaymentSource,
      primarySubscription: {
        isActive,
        isReactivatableV2,
        currentTermEnd,
        pauseTime,
        resumeTime,
        trialEndTime,
        isPauseScheduled,
        status: subscriptionStatus,
        nextBillingAt,
        plan,
        invoiceEstimate,
      },
      scheduledSubscriptionChange,
    },
  } = data;

  const handleUpdateStripePayment = (token: Token) => {
    const options = {
      token: token.id,
      gatewayProvider: PAYMENT_GATEWAYS.stripe,
      type: PAYMENT_METHOD_TYPES.card,
    };

    userUpdatePaymentSource({
      variables: options,
    });
  };

  const handleUpdateBraintreePayment = (nonce: any) => {
    const options = {
      token: nonce,
      gatewayProvider: PAYMENT_GATEWAYS.braintree,
      type: PAYMENT_METHOD_TYPES.paypal_express_checkout,
    };

    userUpdatePaymentSource({
      variables: options,
    });
  };

  const handleTogglePaymentDialog = () => {
    setUpdateCardData(null);
    setUpdateCardError(false);
    setShowUpdateCard(prev => !prev);
  };

  const canSwitchPlans = isActive && !isReactivatableV2 && !plan.isSpecial;

  return (
    <Flex width={{ _: "100%", md: "60%" }} flexDirection="column">
      <Flex flexDirection="column">
        {plan && (
          <SubInfoWrapper>
            <Label>Current Plan</Label>
            <P1>
              {removeHyphen(plan.name)}
              {canSwitchPlans && (
                <Div textAlign="right">
                  <SpanLink
                    color="blue"
                    onClick={() =>
                      history.push(Routes.account__subscription__switch_plan)
                    }
                  >
                    Switch Plan
                  </SpanLink>
                </Div>
              )}
            </P1>
          </SubInfoWrapper>
        )}

        {scheduledSubscriptionChange && (
          <SubInfoWrapper>
            <Label>Upcoming Plan Change</Label>
            <P1>{removeHyphen(scheduledSubscriptionChange.plan.name)}</P1>
          </SubInfoWrapper>
        )}

        {subscriptionStatus && (
          <SubInfoWrapper>
            <Label>Account Status</Label>
            <P1 textTransform="capitalize">
              {removeUnderscore(subscriptionStatus)}
            </P1>
          </SubInfoWrapper>
        )}

        {(subscriptionStatus === SUBSCRIPTION_STATUSES.paused ||
          isPauseScheduled) && (
          <SubInfoWrapper>
            <Label>Pause Dates</Label>
            <Flex flexDirection="column" alignItems="flex-end">
              <P1>
                <Moment date={pauseTime} format="MMM D, YYYY" /> -{" "}
                <Moment date={resumeTime} format="MMM D, YYYY" />
              </P1>
            </Flex>
          </SubInfoWrapper>
        )}

        {currentTermEnd &&
          isActive &&
          subscriptionStatus !== SUBSCRIPTION_STATUSES.in_trial && (
            <SubInfoWrapper>
              <Label>Account Active Until</Label>
              <P1>
                <Moment date={currentTermEnd} format="MMM D, YYYY" />
              </P1>
            </SubInfoWrapper>
          )}

        {subscriptionStatus === SUBSCRIPTION_STATUSES.in_trial && (
          <SubInfoWrapper>
            <Label>In Trial Until</Label>
            <P1>
              <Moment date={trialEndTime} format="MMM D, YYYY" />
            </P1>
          </SubInfoWrapper>
        )}

        {nextBillingAt && invoiceEstimate?.amountDue >= 0 && (
          <SubInfoWrapper>
            <Label>Next Billing Amount</Label>
            <P1>${invoiceEstimate?.amountDue / 100}</P1>{" "}
          </SubInfoWrapper>
        )}

        {nextBillingAt && (
          <SubInfoWrapper>
            <Label>Next Billing Date</Label>
            <P1>
              <Moment date={nextBillingAt} format="MMM D, YYYY" />
            </P1>
          </SubInfoWrapper>
        )}
        <PaymentMethod
          primaryPaymentSource={primaryPaymentSource}
          handleTogglePaymentDialog={handleTogglePaymentDialog}
          showUpdateCard={showUpdateCard}
        />
      </Flex>
      <Flex
        display={showUpdateCard ? "flex" : "none"}
        flexDirection="column"
        maxWidth="400px"
      >
        <Elements stripe={stripePromise}>
          <CreditWrapper>
            <H3>Credit/Debit</H3>
            <PoweredByStripeIcon />
          </CreditWrapper>
          <CardPaymentOptions />
          <StripeCard
            onError={err => setUpdateCardError(err)}
            onSubmit={handleUpdateStripePayment}
            buttonMessage="Update Card"
            parentLoading={userUpdatePaymentSourceResults.loading}
          />
        </Elements>
        <PaypalButton
          onSubmit={handleUpdateBraintreePayment}
          onError={setUpdateCardError}
        />
      </Flex>
      {updateCardData && (
        <Alert variant="success" closeAlert={() => setUpdateCardData(null)}>
          Payment method updated!
        </Alert>
      )}
      {updateCardError && (
        <Alert variant="danger" closeAlert={() => setUpdateCardError(null)}>
          Unable to update payment method. Please try again or
          <SpanLink onClick={() => zendesk("webWidget", "open")}>
            {" "}
            contact us for help!
          </SpanLink>
        </Alert>
      )}
    </Flex>
  );
};

export default SubscriptionInfo;
