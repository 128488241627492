import { PaymentMethodType, PaymentSource } from "services/graphql";

export function convertPaymentSourceToString(
  paymentSource: Partial<PaymentSource>
) {
  const { type, card } = paymentSource;

  if (type === PaymentMethodType.PaypalExpressCheckout) {
    return {
      paymentMethodCopy: "Paypal",
      subscribeCopy: "Subscribe Now With Paypal",
    };
  }

  if (!card) {
    return {
      paymentMethodCopy: "",
      subscribeCopy: "",
    };
  }

  return {
    paymentMethodCopy: `${card.brand?.toUpperCase()} ${card.fundingType} ${
      card.object
    } ending in ${card.lastFour}`,
    subscribeCopy: "Subscribe Now With Card",
  };
}
